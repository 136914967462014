<template>
  <div id="app">
    <Presentation/>
    <Slide/>
    <Portfolio/>
    <Why/>
    <Tarif/>
    <Contact/>
    <Cookies/>
  </div>
</template>

<script>
import Presentation from './components/Presentation.vue';
import Slide from './components/Slide.vue';
import Portfolio from './components/Portfolio.vue';
import Why from './components/Why.vue';
import Tarif from './components/Tarif.vue';
import Contact from './components/Contact.vue';
import Cookies from './components/Cookies.vue';
export default {
  name: 'App',
  components: {
    Presentation,
    Slide,
    Portfolio,
    Why,
    Tarif,
    Contact,
    Cookies
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 30px 0;
  box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #2196f3;
  min-height: 100vh;
  overflow: hidden;
}
</style>
