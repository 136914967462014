<template >
<div id="portfolio" :style="{opacity, transition}">
    <h2>Sélection de projets</h2>
    <div class="items">
    <div class="item">
            <a href="https://lesbricolesdenicole.fr/" target="blanck">
                <img src="img/lesbricoles.png">
            </a>
        </div>
        <div class="item">
            <a href="https://www.leparisien.fr/hauts-de-seine-92/hauts-de-seine-un-village-virtuel-pour-participer-au-telethon-presque-normalement-04-12-2020-8412313.php" target="blanck">
                <img src="img/telethon2.png">
            </a>
        </div>
        <div class="item">
           <a href="https://kopter.fr/" target="blanck">
                <img src="img/drone.png">
            </a>
        </div>
        <div class="item">
             <a href="https://spac.heryravelojaona.fr" target="blanck">
                <img src="img/twirl.png">
            </a>
        </div>
    </div>
</div>
    
</template>
<script>
export default {
    name:"Portfolio",
    data: function () {
        return {
            opacity: 0.1,
             transition: '0.4s ease-in 0.7s'
        }
        
    },
    methods: {
        test() {
            if(window.scrollY > document.getElementById('portfolio').offsetTop - document.getElementById('portfolio').offsetHeight +150) {
                this.opacity = 1;
            }
        }
    },
    mounted() {
        document.addEventListener('scroll', this.test); 
    },
    destroyed() {
        window.removeEventListener('scroll', this.test);
    }
}
</script>
<style scoped>

#portfolio {
    background-color: #000;
    width: 100%;
    overflow: hidden;
    position: relative;
    transition: 0.2s ease 0.6s opacity;
}

h2 {
    padding: 5px 10px;
    margin-top: 50px;
    text-transform: capitalize;
    color: #fff;
    letter-spacing: 2px;
    -webkit-text-stroke: 0.7px #000;
    font-family: "Monument Extended";
    font-size: 2em;
}
.items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 16%;
}
.item {
    margin: 20px 0;
    background-color: #ddd;
    height: 200px;
    width: 40%;
    transition: 0.2s ease 0.2s;
    position: relative;
    overflow: hidden;
}

.item img {
    width: 100%;
    height: 100%;
}

.item p {
    font-size: 0.7em;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0 2px;
}

.item:nth-child(even)::after {
    content: "";
    position: absolute;
    background-color: rgb(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    transition: 0.2s ease 0.2s;
    font-size: 0.9em;
    color:#fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-family: 'Poppins', sans-serif;
}


.item:nth-child(odd)::after {
    content: "";
    position: absolute;
    background-color: rgb(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    transition: 0.2s ease 0.2s;
    font-size: 0.9em;
    color:#fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-family: 'Poppins', sans-serif;
}
.item:nth-child(2)::after {
    content: "Lead développeur FrontEnd pour le Village Virtuel Téléthon 2020";
}

.item:nth-child(3)::after {
    content: "Drône (site Wordpress) ";
}
.item:nth-child(4)::after {
    content: "Association twirling bâton"; 
}
.item:nth-child(1)::after {
    content: "Les bricoles de Nicole, boutique Zéro déchet";
}


.item:hover {
    box-shadow: 0 0 8px #2196f3, 0 0 8px #2196f3, 0 0 15px #2196f3;
    transform: scaleX(0.9);
}

.item:hover.item:nth-child(even)::after {
    right: -100%;
    font-size: 0.3em;
}

.item:hover.item:nth-child(odd)::after {
    left: -100%;
    font-size: 0.3em;
}

@media screen and (min-width: 900px) {
  .item {
    margin: 40px 0;
    background-color: #ddd;
    height: 500px;
    width: 40%;
    transition: 0.2s ease 0.2s;
    position: relative;
    overflow: hidden;   
    }

    .items {
        margin-top: 3%;
        margin-bottom: 3%;      
    }
 
    .item:nth-child(odd)::after,
    .item:nth-child(even)::after
     {

        font-size: 1.9em;

    }

}

</style>