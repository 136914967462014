<template>
<div id="contact">
    <h2>Contactez-moi</h2>
    <div class="content">
        <div class="item"><a href="tel:+33609934256"><span><i class="fas fa-phone-volume"></i> Tél</span>: 06 09 93 42 56</a></div><br/>
        <div class="item"><a href ="sms:+33609934256">Par message <i class="fas fa-sms"></i></a></div><br/>
        <div class="item"><a href="mailto:hery@heryravelojaona.fr"><i class="fas fa-envelope-square"></i>  hery@heryravelojaona.fr</a></div>
    </div>
</div>

</template>
<script>
export default {
    name : "Contact"
}
</script>
<style scoped>
#contact {
    margin-bottom: 30px;
}

h2 {
    padding: 5px 10px;
  font-size: 2em;
  color: #000;
  font-family: "Monument Extended";
  letter-spacing: 3px;
}

#contact a {
    color:#ddd;
    width: 200px;
    height: 100%;
    font-size: 1em;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    font-weight: 900;
    letter-spacing: 1px;
    margin: 0 auto;
    width: 80%;
    text-align: center;
    font-style: italic;
    background: #000;
    border-radius: 3px;
    padding: 5px 15px;

}

#contact a:hover{
    box-shadow: 0 0 10px #2196f3, 0 0 20px #2196f3, 0 0 40px #2196f3;
    color: #fff;
}


@media screen and (min-width: 900px) {
  #contact a {
    font-size: 1.8em;
  }
}




</style>

