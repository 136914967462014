<template>
<div id="cookies">
    <div class="content">
         <p>Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site web.<br/> Si vous continuez à utiliser ce site, nous supposerons que vous en êtes satisfait.</p>
    <div class="container-btn">
         <button  id="accept-cookie" class="set-cookie" aria-label="D'accord">D'accord !</button>
    </div>
    </div>
</div>
    
</template>
<script>
export default {
    name: "Cookies"
}
</script>
<style scoped>
#cookies {
    display: none;
    width: 100%;
    background-color: #000;
    color: #fff;
    position: fixed;
    bottom: 0; 
}

.content {
    padding: 10px;
    color: #fff;
}

#accept-cookie {
    background-color:  #2196f3;
    border-radius: 3px;
    padding: 10px 25px;
    color: #fff;
    font-size: 1em;
}

#accept-cookie:hover {
    opacity: 0.7;
}
</style>