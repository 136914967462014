<template>
<div id="tarif">
    <div class="devis">
        <span class="border-effect"></span>
        <span class="border-effect"></span>
        <span class="border-effect"></span>
        <span class="border-effect"></span>
        <h2>Parce que vous êtes unique</h2>
        <p class="list-p">Un tarif adapté à vos besoins uniquement sur devis</p>
        <p class="list-p">Je me charge de retranscrire vos envies et idées en un site internet qui vous correspond</p>
        <p class="list-p">Mon objectif est de vous accompagner et vous procurer entière satisfaction du début à la fin de la prestation</p>
        <p>Je me ferai un plaisir de discuter avec vous de vos projets</p>
    </div>

</div>
    
</template>
<script>
export default {
    name: "Tarif"
}
</script>
<style scoped>
.border-effect:nth-child(1) {
        position: absolute;
      top: 2px;
      left: -100%;
      width: 100%;
      height: 10px;
      background: linear-gradient( 90deg, transparent, #2196f3 );
} 

.devis:hover .border-effect:nth-child(1) {
      left: 100%;
      transition: 1s;
} 

.devis .border-effect:nth-child(3) {
    position: absolute;
      bottom: 3px;
      right: -100%;
      width: 100%;
      height: 10px;
      background: linear-gradient( 270deg, transparent, #2196f3 );
} 
.devis:hover .border-effect:nth-child(3) {
      right: 100%;
      transition: 1s;
      transition-delay: 0.4s;
}

.border-effect:nth-child(2) {
    position: absolute;
      top: -100%;
      right: 2px;
      width: 4px;
      height: 100%;
      background: linear-gradient( 180deg, transparent, #dac9ad);
} 

.devis:hover .border-effect:nth-child(2) {
      top: 100%;
      transition: 1s;
      transition: 1.5s;
} 

.border-effect:nth-child(4) {
    position: absolute;
      bottom: -100%;
      left: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient( 360deg, transparent, #dac9ad );
} 

.devis:hover .border-effect:nth-child(4) {
      bottom: 100%;
      transition: 1s;
      transition-delay: 0.60s;
} 

.border-effect {
      position: absolute;
      display: block;
} 
#tarif {
   
    background-color: #000;
}

h2 {
  padding: 5px 10px;
  font-size: 1.8em;
  color: #fff;
  font-family: "Monument Extended";
  letter-spacing: 3px;
}
.devis {
    position: relative;
    padding: 20px;
    color: #fff;
}

.devis p {
    text-align: left;
    line-height: 1.3;
    font-family: 'Poppins', sans-serif;
}

@media screen and (min-width: 900px) {
  .devis p {
    font-size: 1.5em;
    width:33%;
    margin: 40px auto;
    }

    h2 {
     font-size: 2.4em;
    }
}

.list-p{
  position: relative;
  padding-bottom: 15px;
}
.list-p::after {
  content:"";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
}

.list-p:nth-child(even)::after {
  background: linear-gradient(90deg,rgba(0,212,255,0.7) 0%, rgba(33,150,243,0.6) 80%, rgba(2,0,36,1) 100%);
}

.list-p:nth-child(odd)::after {
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(33,150,243,0.7) 85%, rgba(0,212,255,0.7) 100%);
}

</style>