<template>
    <div id="why">
        <div id="content">
            <h2>Stack Technique </h2>
            <div class="stack">
                <div class="item"><img src="/img/vuejs.png"></div>
                <div class="item"><img src="/img/react.png"></div>
                <div class="item"><img src="/img/HTML-CSS.jpg"></div>
                <div class="item"><img src="/img/sass.png"></div>
                <div class="item"><img src="/img/jquery.jpg"></div>
                <div class="item"><img src="/img/bootstrap.png"></div>
                <div class="item"><img src="/img/php.png"></div>
                <div class="item"><img src="/img/SQL.png"></div>
                <div class="item"><img src="/img/shopify.jpg"></div>
                <div class="item"><img src="/img/wordpress.jpg"></div>
                <div class="item"><img src="/img/snipcart_logo.svg"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Why"
}
</script>
<style scoped>
#why {
    overflow: hidden;
    height: auto;
}
h2 {
  padding: 5px 10px;
  font-size: 2em;
  color: #000;
  font-family: "Monument Extended";
  letter-spacing: 3px;
}

h2 span {
    color: #000;
    font-size: 0.5em;
    font-style: italic;
    font-family: 'Poppins', sans-serif;
}
.stack {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
}

.stack .item {
    width: 40%;
    margin: 10px 0;
}

.stack .item img {
    height: 80px;
    width: 100%;
    margin: 0;
    padding: 0;
}


@media screen and (min-width: 900px) {
  .stack .item img {
    height: 200px;
    width: auto;
    margin: 0;
    padding: 0;
    }

    .stack .item {
    width: 23%;
    margin: 50px 0;
}
}
</style>