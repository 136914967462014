<template>
  <div id="hello">
    <div class="bloc-one">
      <div class="text ">
        <ul>
            <li>
              <h4 class="list-p">Création ou refonte de site internet</h4>
              <h4 class="list-p">Gestion complète de projet pour un site entièrement<br> sur-mesure</h4>
              <h4 class="list-p">Site parfaitement adapté à tous supports</h4>
              <h4 class="list-p">Site vitrine, site Ecommerce, portfolio, blog…</h4> 
              <h4 class="list-p">Référencement naturel optimisé</h4>
              <h4 class="list-p">Design personnalisé</h4>
         
              <h4 class="list-p">Création d'application web</h4>
              <h4 class="list-p-last">Performance et visibilité</h4>
            </li>
        </ul>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Presentation'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#hello {
  padding-top: 30px;
  background-color: #000;
  min-height: 100vh;
}
.why-block {
  background-image:  url('/../img/bloc1.jpg');
  background-position: center;
  background-attachment: initial;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  min-height: 200px;
}

.bloc-one {
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-family: "Monument Extended";
}

h2 {
  padding: 5px 10px;
  font-size: 1.1em;
  color: #fff;
  font-family: "Monument Extended";
  letter-spacing: 3px;
}

.text {
  background-color: #000;
  font-size: 1em;
  line-height: 1;
  padding: 0 5px;
}

.text p {
  font-size: 1em;
  text-align: center;
}

.text h4 {
  overflow-wrap: break-word;
  color: #fff;
  letter-spacing: 2px;
  -webkit-text-stroke: 0.7px #000;
  line-height: 1.3;
  font-size: 1.1em;
  font-weight: 700;
  margin: 10px 0 0;
  padding: 15px;
  font-family: 'Poppins', sans-serif;
  font-style: italic;
  text-align: left;
  position: relative;
}

.list-p::after {
  content:"";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
}

.list-p:nth-child(even)::after {
  background: linear-gradient(90deg,rgba(0,212,255,0.7) 0%, rgba(33,150,243,0.6) 80%, rgba(2,0,36,1) 100%);
}

.list-p:nth-child(odd)::after {
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(33,150,243,0.7) 85%, rgba(0,212,255,0.7) 100%);
}

.text h4:nth-child(9)::after {
  content: "";
}

.text h4:nth-child(even){
  text-align: right;
}



.text p {
  color: #fff;
}

.text ul {
  margin: 0 0;
  padding: 0;
}

ul {
  margin: 0 0;
  list-style: none;
}


@media screen and (min-width: 475px) {
  .why-block {
  background-repeat: no-repeat;
  background-size: contain;
  width: 40%;
  height: auto;
}

.bloc-one {
  flex-direction: row;
  padding: 40px;
  justify-content: center;
  align-items: stretch;
}

.text {
  height: 100%;
  width: 80%;
  font-size: 1.4em;
  line-height: 1;
}

.text h4 {
  line-height: 1;
  font-size: 1.2em;
  font-weight: 900;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  margin: 40px 0;
}

  .text p {
    text-align: justify;
    font-family: serif;
  }

}

</style>
