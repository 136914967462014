<template>

<div id="slider">
  
    <div class="container-slider" >
      <div class="slide">
        <p class="text-slider">"Pour créer votre site internet ou l'améliorer, je mettrai tout mon savoir-faire à votre service. Et le savoir-être, c'est offert"</p>
      </div>
   </div>
</div>
</template>
<script>

export default {
     name: 'Slide'
}
</script>
<style scoped>
#slider {
  margin: 0px auto;
  background-color: #fff;
  height: auto;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.container-slider {
    width: 100%;
}

.slide {
  display: flex;
  align-items: center;
  text-align: center;
  height: 150px;
  min-width: 100%;
}

.text-slider {
  font-size: 1em;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  letter-spacing: 1px;
  margin: 0 auto;
  width: 80%;
  text-align: center;
  font-style: italic;
}

@media screen and (min-width: 900px) {
  button {
  font-size: 2.5em;
  }
  .text-slider {
  font-size: 1.8em;
  font-style: italic;
 }
}

</style>